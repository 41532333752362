var render, staticRenderFns
import script from "./WorkDetail9.vue?vue&type=script&lang=js&"
export * from "./WorkDetail9.vue?vue&type=script&lang=js&"
import style0 from "./WorkDetail9.vue?vue&type=style&index=0&id=db3e81c4&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db3e81c4",
  null
  
)

/* custom blocks */
import block0 from "./WorkDetail9.vue?vue&type=custom&index=0&blockType=div&class=workDetail"
if (typeof block0 === 'function') block0(component)

export default component.exports